<template>
    <div class="contract-info" style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
        <div class="service-body">
            <div class="service-left">
                <div style="display: flex;">
                    <div class="head-card">
                        <div style="display: flex; justify-content: space-between">
                            <div class="work-title">
                                <div class="work-icon"></div>
                                <div>服务订单信息</div>
                            </div>
                            <div style="margin-right: 10px; color: #999999; margin-top: 10px">
                                订单号：20220317_164946_206070
                            </div>
                        </div>
                        <div style="padding:16px 20px; display: flex">
                            <div style="width: 178px; height: 124px; background-color: #999999; margin-right: 20px">
                            </div>
                            <div style="width: 80%">
                                <div style="color: #031F88; font-size: 22px; font-weight: 600; margin-bottom: 10px">公司企业品牌识别logo设计标志图标商标光荣文案卡通字体门头vi设计</div>
                                <div style="display: flex; justify-content: space-between">
                                    <div><span style="color: #EE3F4D; font-size: 22px; font-weight: 600">￥ 140.00/页</span></div>
                                    <div>
                                        <el-input-number v-model="num" :min="1" :max="100" size="small"></el-input-number>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="body-card">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>服务订单详情</div>
                    </div>
                    <div style="padding:20px 40px">
                        服务委托的依据和技术标准
                        匠二品牌，国内专业品牌设计机构，坚持创新个性设计，物美价廉的品质获得诸多客户认可，集齐不同性格设计手赋予企业新鲜活
                        力创造力，专注平面设计，致力于以高效的方式解决客户遇到的设计难题，与客户携手共创更好的品牌形象。匠二品牌，国内专业
                        品牌设计机构，坚持创新个性设计，物美价廉的品质获得诸多客户认可，集齐不同性格设计手赋予企业新鲜活力与创造力，专注平
                        面设计，致力于以高效的方式解决客户遇到的设计难题，与客户携手共创更好的品牌形象。匠二品牌，国内专业品牌设计机构，坚
                        持创新个性设计物美价廉的品质获得诸多客户认可，集
                    </div>
                </div>

                <div class="body-card">
                    <div style="padding:20px 40px">
                        <div style="font-size: 20px; font-weight: 600">
                            请选择是否需要百工宝平台为您提供以下服务：
                        </div>

                        <div>
                            <div class="checkbox-style">
                                <el-checkbox :label="1">邀请该领域专家对服务订单及成果提出建议（需额外支付邀请专家的费用，由平台随机推送专家）</el-checkbox>
                            </div>
                            <div class="checkbox-style">
                                <el-checkbox :label="2">设计图版权保护（不允许服务方将该服务设计图上传至服务方服务案例中）</el-checkbox>
                            </div>
                            <div class="checkbox-style">
                                <el-checkbox :label="3">企业品牌商标保护</el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body-card">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>专家审查意见</div>
                    </div>
                    <div style="padding:20px 40px; overflow: hidden">
                        <div style="margin-bottom: 30px">
                            <div class="project-title">委托需求名称</div>
                            <el-input placeholder="请填写需要委托的服务名称" maxlength="30" show-word-limit></el-input>
                        </div>

                        <div>
                            <div class="project-title">委托服务具体要求</div>
                            <el-input type="textarea" placeholder="请填写需要委托的服务具体要求" maxlength="300" rows="10" show-word-limit></el-input>
                        </div>

                        <div style="display: flex; justify-content: end; margin-top: 30px;">
                            <div class="hand op foot-btn" style="background-color: #F0F2F5; margin-right: 20px">保存</div>
                            <div class="foot-btn hand op" style="color: #FFFFFF; background-color: #2970FF">提交订单信息</div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="service-right">
                <div class="body-card" style="margin: 0; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>交易流程</div>
                    </div>
                </div>

                <div class="body-card" style="margin: 10px 0; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>百工宝平台保障</div>
                    </div>

                    <div style="margin: 20px 26px;">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contractInfo",
        data() {
            return {

            }
        },

        mounted() {
        },

        methods: {}
    }
</script>

<style scoped>
    .service-body {
        width: 1500px;
        display: flex;
        margin: 100px auto;
    }

    .service-left {
        width: 1136px;
        margin-right: 10px;
    }

    .service-right {
        width: 354px;
        overflow: hidden;
    }

    .head-card {
        width: 100%;
        height: 200px;
        background-color: #FFFFFF;
    }

    .body-card {
        margin-top: 10px;
        width: 100%;
        background-color: #FFFFFF;
    }

    .service-body .work-title {
        display: flex;
        line-height: 20px;
        padding-top: 16px;
        font-size: 18px;
        font-weight: 600;
        color: #031F88;
    }

    .service-body .work-title .work-icon {
        width: 6px;
        height: 20px;
        background-color: #FF9800;
        margin-right: 14px;
    }

    .service-body .title {
        width: 100px;
        color: #888888;
    }

    .service-body .content {
        width: 260px;
    }

    .service-body .foot-btn {
        width: 108px;
        height: 24px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 6px;
    }

    .service-body .checkbox-style {
        font-size: 16px;
        margin: 20px 0;
    }

    .service-body .project-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
    }

</style>
